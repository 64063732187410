.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* color in darktheme */
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
    -webkit-transition: .4s;
    transition: .4s;

}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    /* ballcolor */
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4), inset 0px -5px 15px rgba(255, 255, 255, 0.4);

    -webkit-transition: .4s;
    transition: .4s;

}

input:checked+.slider {
    /* color in white theme  */
    background-image: linear-gradient(to right, #868f96 0%, #596164 100%);
}



input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}