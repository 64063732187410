@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* #3da2ff  181c32*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    font-weight: 400;

}

/* linear-gradient(150deg, rgb(254, 248, 228) 25%, rgb(252, 238, 203) 25%, rgb(252, 238, 203) 75%, rgb(254, 248, 228) 33%); */

.herohoni {
    background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    background-repeat: repeat;
    background-size: cover;
    background-position: center;
    height: fit-content;
    /* backdrop-filter: blur(10px); */
    /* Adjust the blur intensity as needed */
}


.herohoni-alternate {
    background-color: #181c32;
    color: white;
}


.kxa {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.918);
    height: 4vh;

}

.shadow {
    cursor: pointer;
    transition: 0.2s ease-in-out;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgba(255, 255, 255, 0.3);
}

.shadow:hover {
    box-shadow: rgba(206, 206, 206, 0.541) 0px 7px 29px 0px;
    border: 1px solid black;

}